import getLocale from '@/utils/getLocale'

const weekDaysByLocale = {
  'pt-PT': [
    'Domingo',
    'Segunda feira',
    'Terça feira',
    'Quarta feira',
    'Quinta feira',
    'Sexta feira',
    'Sábado',
  ],
  'en-US': [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ],
}

const getWeekDay = (date, locale = 'en-US') => {
  if (!date) {
    return null
  }

  return weekDaysByLocale[getLocale(locale)][new Date(date).getDay()]
}

export default getWeekDay
