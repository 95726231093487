import React from 'react'
import * as UI from '@/components/UI'
import srcMoon from '@/assets/images/nobox-green.png'
import srcYellow from '@/assets/images/nobox-yellow.png'
import srcCloud from '@/assets/images/nobox-blue.png'
import { Section, Cell, Title, Paragraph, List, Item } from './About.css'

const About = ({ content }) => {
  if (!content?.about_title) return null
  return (
    <Section>
      <UI.Parallax top='0%' left='0%' force={1.1} offsetComp={20}>
        {offSet => (
          <UI.ImageLoose
            style={{
              transform: `translateY(${offSet}px) translateZ(0) rotate(90deg)`,
            }}
            src={srcYellow}
            alt={content.about_title}
            width='360'
            hiddenMobile
          />
        )}
      </UI.Parallax>
      <UI.Parallax top='60%' right='10%' force={1.2} offsetComp={10}>
        {offSet => (
          <UI.ImageLoose
            style={{
              transform: `translateY(${offSet}px) translateZ(0) rotate(-115deg)`,
            }}
            src={srcCloud}
            alt={content.about_title}
            width='340'
            hiddenMobile
          />
        )}
      </UI.Parallax>
      <UI.Parallax top='0%' right='250px' force={1.4} offsetComp={20}>
        {offSet => (
          <UI.ImageLoose
            style={{
              transform: `translateY(${offSet}px) translateZ(0)`,
            }}
            src={srcMoon}
            alt={content.about_title}
            width='340'
            hiddenMobile
          />
        )}
      </UI.Parallax>
      <UI.Container>
        {content.about_title && (
          <UI.Grid>
            <Cell>
              <Title>{content.about_title}</Title>
              {content.about_paragraphs.map(({ paragraph }) => (
                <Paragraph key={paragraph}>{paragraph}</Paragraph>
              ))}
            </Cell>
          </UI.Grid>
        )}
        {content.about_list_title && (
          <UI.Grid>
            <Cell>
              <Title>{content.about_list_title}</Title>
              <List>
                {content.about_list.map(({ item }) => (
                  <Item key={item}>{item}</Item>
                ))}
              </List>
            </Cell>
          </UI.Grid>
        )}
      </UI.Container>
    </Section>
  )
}

export default About
