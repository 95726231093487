import React from 'react'
import * as UI from '@/components/UI'
import { Section, Grid, Main, Title, ImageWrapper } from './Hero.css'

const Hero = ({ content, ...props }) => {
  return (
    <Section {...props}>
      <UI.Container>
        <Grid>
          <Main>
            <UI.Grid>
              <Title>{content.hero_title}</Title>
            </UI.Grid>
            <ImageWrapper>
              <UI.AspectRatio ratio={3 / 2}>
                <UI.Image
                  alt={content?.hero_image?.alt || content?.hero_title}
                  src={content.hero_image}
                />
              </UI.AspectRatio>
            </ImageWrapper>
          </Main>
        </Grid>
      </UI.Container>
    </Section>
  )
}

export default Hero
