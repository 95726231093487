import styled from 'styled-components'
import above from '@/utils/above'
import getColumnSize from '@/utils/getColumnSize'

export const Section = styled.section`
  padding-bottom: 4rem;
  position: relative;
  ${above('md')`
    padding-bottom: 6.25rem;
  `}
`

export const Cell = styled.div`
  width: calc(2rem + ${getColumnSize(12)});
  margin: 0 -1rem;
  ${above('md')`
    margin: 0;
    width: ${getColumnSize(12)};
  `}
  ${above('lg')`
    width: ${getColumnSize(8)};
    margin-left: ${getColumnSize(4)};
    margin-right: 0;
  `}
  ${above('xg')`
    width: ${getColumnSize(9)};
    margin-left: ${getColumnSize(3)};
  `}
`
