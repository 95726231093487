import React from 'react'
import flatMap from '@/utils/flatMap'
import * as UI from '@/components/UI'
import { Section, Cell } from './Testimonials.css'

const Testimonials = ({ content }) => {
  const testimonials =
    flatMap(content?.testimonials, x => x?.testimonial?.document)?.map(
      x => x?.data,
    ) || []

  if (!testimonials?.[0]?.content) return null
  return (
    <Section>
      <UI.Container>
        <UI.Grid>
          <Cell>
            <UI.TestimonialGroup
              testimonials={testimonials}
              color='darkYellow'
            />
          </Cell>
        </UI.Grid>
      </UI.Container>
    </Section>
  )
}

export default Testimonials
