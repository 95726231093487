import React from 'react'
import cardsColorsTypes from '@/constants/cardsColorsTypes'
import * as UI from '@/components/UI'
import { useContext } from '@/store'
import getCorrectSlug from '@/utils/getCorrectSlug'
import { Section, Container, Grid, Cell } from './Prefooter.css'

const Prefooter = ({ content: { prefooter_cards: cards } }) => {
  const { locale } = useContext()
  return (
    <Section>
      <Container>
        {!!cards.length && (
          <Grid vertical-gutter>
            {cards.map((card, index) => {
              if (!card.card) {
                return null
              }
              const data = card?.card?.document?.data

              if (!data) {
                return null
              }

              const {
                title,
                description,
                type,
                type: { type: contentType, uid: slug },
              } = data

              const newSlug = getCorrectSlug({
                locale,
                type: contentType,
                slug,
              })

              const eventContent = type.document?.[0]

              const eventContentDate = eventContent && eventContent?.data?.date
              const isEventType = contentType === 'event'

              if (index < 2) {
                return (
                  <Cell oneItem={cards.length < 2} key={title}>
                    <UI.Card
                      fixColorInMobile
                      isEventType={isEventType}
                      date={eventContentDate}
                      isPrefooter
                      to={`/${newSlug.replace(/^\//, '')}`}
                      color={cardsColorsTypes[contentType]}
                      title={title}
                      description={description}
                      type='prefooter'
                    />
                  </Cell>
                )
              }
              return null
            })}
          </Grid>
        )}
      </Container>
    </Section>
  )
}

export default Prefooter
