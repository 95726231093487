const types = {
  courses_page: {
    'pt-pt': '',
    'en-us': '',
  },
  course: {
    'pt-pt': 'programas',
    'en-us': 'programs',
  },
  serious_games_page: {
    'pt-pt': '',
    'en-us': '',
  },
  serious_game: {
    'pt-pt': 'experiencias',
    'en-us': 'serious-games',
  },
  events_page: {
    'pt-pt': '',
    'en-us': '',
  },
  event: {
    'pt-pt': 'eventos',
    'en-us': 'events',
  },
  pain_finder_page: {
    'pt-pt': '',
    'en-us': '',
  },
}

export default ({ locale, type, slug }) => {
  const contentType = types[type]

  return `${contentType[locale]}/${slug}`
}
