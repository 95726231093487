import styled from 'styled-components'
import above from '@/utils/above'
import getColumnSize from '@/utils/getColumnSize'
import * as UI from '@/components/UI'

export const Section = styled.section`
  position: relative;
  z-index: 20;
  background-color: ${p => p.theme.colors.maastricht};
`

export const Container = styled(UI.Container)`
  max-width: 100%;
  ${above('md')`
   margin: 0;
   padding: 0 1rem;  
  `}
`
export const Grid = styled(UI.Grid)`
  align-items: stretch;
  height: 100%;
`

export const Cell = styled.div`
  height: auto;
  width: ${getColumnSize(12)};
  ${above('md')`
    width: ${p => getColumnSize(p.oneItem ? 12 : 6)}
  `}
  ${above('lg')`
    width: $${p => getColumnSize(p.oneItem ? 12 : 6)}
  `}
  ${above('xg')`
    width: ${p => getColumnSize(p.oneItem ? 12 : 6)}
  `}
`
