import React, { useState } from 'react'
import * as UI from '@/components/UI'
import srcMoon from '@/assets/images/nobox-green.png'
import srcYellow from '@/assets/images/nobox-yellow.png'
import { Section, Cell, Title, Button, Day, List } from './Schedule.css'

/* eslint-disable */
const groupBy = (arr, key) =>
  arr.reduce((r, v, i, a, k = v[key]) => ((r[k] || (r[k] = [])).push(v), r), {})
/* eslint-enable */

const p2li = s => s?.replace(/(<\s*\/?\s*)p(\s*([^>]*)?\s*>)/gi, '$1li$2') || ''

const Schedule = ({ content }) => {
  const [selectedDate, setSelectedDate] = useState(content?.schedule?.[0]?.date)

  if (!content?.schedule?.[0]?.date) return null
  const grouped = groupBy(content.schedule, 'date')
  const dates = Object.keys(grouped)

  return (
    <Section>
      <UI.Parallax top='-50%' right='20%' force={1.1} offsetComp={20}>
        {offSet => (
          <UI.ImageLoose
            style={{
              transform: `translateY(${offSet}px) translateZ(0) rotate(45deg)`,
            }}
            src={srcYellow}
            alt={content.about_title}
            width='360'
            hiddenMobile
          />
        )}
      </UI.Parallax>
      <UI.Parallax top='-20%' left='1%' force={1.4} offsetComp={20}>
        {offSet => (
          <UI.ImageLoose
            style={{
              transform: `translateY(${offSet}px) translateZ(0)`,
            }}
            src={srcMoon}
            alt={content.about_title}
            width='340'
            hiddenMobile
          />
        )}
      </UI.Parallax>
      <UI.Container>
        <UI.Grid>
          <Cell>
            <Title>{content.schedule_title}</Title>
            <UI.Group vertical-gutter gutter={12}>
              {dates.map(date => {
                return (
                  <Button
                    key={date}
                    current={date === selectedDate}
                    onClick={() => setSelectedDate(date)}
                  >
                    {date}
                  </Button>
                )
              })}
            </UI.Group>
            {grouped[selectedDate].map(({ time, content: text }) => (
              <div key={time}>
                <Day>{time}</Day>
                <List dangerouslySetInnerHTML={{ __html: p2li(text.html) }} />
              </div>
            ))}
          </Cell>
        </UI.Grid>
      </UI.Container>
    </Section>
  )
}

export default Schedule
