import React from 'react'
import styled from 'styled-components'
import { graphql } from 'gatsby'

import above from '@/utils/above'
import Layout from '@/layout'
import NavBar from '@/components/Partials/NavBar/NavBar'
import Sidebar from '@/components/contents/EventTemplate/Sidebar'
import Hero from '@/components/contents/EventTemplate/Hero'
import About from '@/components/contents/EventTemplate/About'
import Gallery from '@/components/contents/EventTemplate/Gallery'
import Testimonials from '@/components/contents/EventTemplate/Testimonials'
import Schedule from '@/components/contents/EventTemplate/Schedule'
import Information from '@/components/contents/EventTemplate/Information'
import Footer from '@/components/Partials/Footer/Footer'
import Prefooter from '@/components/Partials/Prefooter/Prefooter'
import getMetaImage from '@/utils/getMetaImage'

const SidebarTrack = styled.div`
  position: relative;
  margin-bottom: 5rem;
  ${above('md')`
    margin-bottom: 10rem;
  `}
`

const DarkWrapper = styled.div`
  background: ${p => p.theme.colors.maastricht};
  color: ${p => p.theme.colors.white};
  position: relative;
  z-index: 20;
`

const EventTemplate = ({ data }) => {
  const content = data?.content?.data

  const metaImage = getMetaImage(content)

  return (
    <Layout
      title={content.meta_title}
      description={content.meta_description}
      image={metaImage}
    >
      <SidebarTrack>
        <DarkWrapper>
          <NavBar />
        </DarkWrapper>
        <Sidebar content={content} />
        <DarkWrapper>
          <Hero content={content} />
        </DarkWrapper>
        <About content={content} />
        <Gallery content={content} />
        <Testimonials content={content} />
        <Schedule content={content} />
        <Information content={content} />
      </SidebarTrack>
      <Prefooter content={content} />
      <Footer />
    </Layout>
  )
}

export const pageQuery = graphql`
  query EventTemplate($url: String!, $locale: String!) {
    content: prismicEvent(uid: { eq: $url }, lang: { eq: $locale }) {
      data {
        meta_title
        meta_description
        meta_image {
          localFile {
            childImageSharp {
              fixed(width: 1200, height: 630) {
                src
              }
            }
          }
        }
        sidebar_kind
        sidebar_description
        formatedDate: date(formatString: "DD.MM, HH:mm")
        date
        to_date
        location
        sidebar_detail
        sidebar_price
        sidebar_currency
        sidebar_price_detail
        sidebar_button
        sidebar_button_link
        hero_title
        hero_image {
          alt
          localFile {
            childImageSharp {
              fluid {
                aspectRatio
                originalName
                sizes
                src
                srcSet
              }
            }
          }
        }
        about_title
        about_paragraphs {
          paragraph
        }
        about_list_title
        about_list {
          item
        }
        gallery {
          title
          image {
            alt
            localFile {
              childImageSharp {
                fluid(maxWidth: 1600) {
                  aspectRatio
                  originalName
                  sizes
                  src
                  srcSet
                }
              }
            }
          }
        }
        testimonials {
          testimonial {
            document {
              ... on PrismicTestimonial {
                data {
                  name
                  position
                  interview_text
                  interview_link
                  content {
                    html
                  }
                  image {
                    alt
                    localFile {
                      childImageSharp {
                        fluid {
                          aspectRatio
                          originalName
                          sizes
                          src
                          srcSet
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        schedule_title
        schedule {
          formatedDate: day
          date: day
          time
          content {
            html
            text
          }
        }
        informations_title
        informations {
          title
          content {
            html
          }
        }
        download_title
        download_button
        download_link {
          url
        }
        prefooter_cards {
          card {
            document {
              ... on PrismicPrefootercard {
                data {
                  title
                  description
                  type {
                    uid
                    type
                    url
                    slug
                    document {
                      ... on PrismicEvent {
                        data {
                          date(formatString: "DD.MM")
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export default EventTemplate
