import React from 'react'
import * as UI from '@/components/UI'
import srcCloud from '@/assets/images/nobox-blue.png'
import {
  Section,
  Cell,
  Title,
  Item,
  ItemTitle,
  ItemText,
  DownloadTitle,
} from './Information.css'

const Information = ({ content }) => {
  return (
    <Section>
      <UI.Parallax bottom='15%' right='20%' force={1.2} offsetComp={10}>
        {offSet => (
          <UI.ImageLoose
            style={{
              transform: `translateY(${offSet}px) translateZ(0) rotate(0)`,
            }}
            src={srcCloud}
            alt={content.informations_title}
            width='340'
            hiddenMobile
          />
        )}
      </UI.Parallax>
      <UI.Container>
        <UI.Grid>
          <Cell>
            {content.informations_title && (
              <Title>{content.informations_title}</Title>
            )}
            {content?.informations?.[0]?.title &&
              content?.informations?.map(({ title, content: text }) => (
                <Item key={title}>
                  <ItemTitle>{title}</ItemTitle>
                  <ItemText dangerouslySetInnerHTML={{ __html: text.html }} />
                </Item>
              ))}
            <DownloadTitle>{content.download_title}</DownloadTitle>
            {content.download_button && (
              <UI.Button appearance='yellow' to={content.download_link.url}>
                <UI.Icon.Download />
                {content.download_button}
              </UI.Button>
            )}
          </Cell>
        </UI.Grid>
      </UI.Container>
    </Section>
  )
}

export default Information
