import styled from 'styled-components'
import above from '@/utils/above'
import getColumnSize from '@/utils/getColumnSize'
import getTypeStyle from '@/utils/getTypeStyle'
import * as UI from '@/components/UI'

export const Section = styled.section`
  padding-top: 4rem;
  position: relative;
  ${above('md')`
    padding-top: 7.5rem;
  `}
  ${above('lg')`
    padding-top: 16.5rem;
  `}
  ${above('xg')`
    padding-top: 20.5rem;
  `}
`

export const Cell = styled.div`
  width: ${getColumnSize(12)};
  padding-bottom: 4rem;
  ${above('md')`
    padding-bottom: 7.5rem;
  `}
  ${above('sm')`
    width: ${getColumnSize(10)};
    margin: 0 ${getColumnSize(1)};
  `}
  ${above('lg')`
    width: ${getColumnSize(8)};
    margin-left: ${getColumnSize(5)};
  `}
  ${above('xg')`
    width: ${getColumnSize(6)};
    margin-left: ${getColumnSize(4)};
  `}
`

export const Title = styled(UI.Subtitle)`
  font-family: ${p => p.theme.type.fonts.headline};
  margin-bottom: 1.5rem;
`

export const Paragraph = styled(UI.Subtitle)`
  color: ${p => p.theme.colors.maastricht}CC;
  margin-bottom: 1.5rem;
  ${getTypeStyle('smallText')};
  ${above('md')`
    ${getTypeStyle('blogText')};
  `}
  ${above('lg')`
    ${getTypeStyle('text')};
  `}
  &:last-child {
    margin-bottom: 0;
  }
`

export const List = styled.ul``

export const Item = styled.li`
  margin-bottom: 1rem;
  display: flex;
  position: relative;
  color: ${p => p.theme.colors.maastricht}CC;
  ${getTypeStyle('smallText')};
  ${above('lg')`
    ${getTypeStyle('text')};
  `}
  &:before {
    content: '—';
    color: ${p => p.theme.colors.yellow};
    margin-right: 0.5rem;
    ${above('xg')`
      position: absolute;
      right: 100%;
    `}
  }
`
