import styled from 'styled-components'
import above from '@/utils/above'
import getColumnSize from '@/utils/getColumnSize'
import * as UI from '@/components/UI'

export const Section = styled.section`
  position: relative;
  z-index: 20;
  ${above('lg')`
    height: calc(75vh + 2rem);
  `}
`

export const Grid = styled(UI.Grid)``

export const Main = styled.div`
  width: ${getColumnSize(12)};
  ${above('lg')`
    width: ${getColumnSize(8)};
    margin-left: ${getColumnSize(4)};
  `}
  ${above('xg')`
    margin-left: ${getColumnSize(3)};
    width: ${getColumnSize(9)};
  `}
`

export const Title = styled(UI.Subtitle).attrs({ as: 'h2' })`
  font-family: ${p => p.theme.type.fonts.headline};
  margin: 4rem 0;
  ${above('sm')`
    max-width: ${getColumnSize(10)};
    margin-left: ${getColumnSize(1)};
  `}
  ${above('md')`
    max-width: ${getColumnSize(8)};
    margin-bottom: 6.25rem;
  `}
  ${above('lg')`
    max-width: 100%;
    margin-left: ${getColumnSize((1 * 12) / 8)};
    margin-right: ${getColumnSize((1 * 12) / 8)};
  `}
  ${above('xg')`
    margin-left: ${getColumnSize((1 * 12) / 9)};
    margin-right: ${getColumnSize((2 * 12) / 9)};
  `}
`

export const ImageWrapper = styled.div``
