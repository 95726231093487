import styled from 'styled-components'
import above from '@/utils/above'
import getColumnSize from '@/utils/getColumnSize'
import getTypeStyle from '@/utils/getTypeStyle'
import * as UI from '@/components/UI'

export const Section = styled.section`
  padding: 2rem 0;
  position: relative;
  ${above('md')`
    padding: 5rem 0;
  `}
`

export const Cell = styled.div`
  ${above('sm')`
    width: ${getColumnSize(10)};
    margin: 0 ${getColumnSize(1)};
  `}
  ${above('md')`
    width: ${getColumnSize(8)};
    margin: 0 ${getColumnSize(2)};
  `}
  ${above('lg')`
    width: ${getColumnSize(6)};
    margin-left: ${getColumnSize(5)};
    margin-right: 0;
  `}
  ${above('xg')`
    width: ${getColumnSize(6)};
    margin-left: ${getColumnSize(4)};
  `}
`

export const Title = styled(UI.Subtitle)`
  font-family: ${p => p.theme.type.fonts.headline};
  margin-bottom: 3rem;
`

export const Button = styled(UI.Label).attrs({ as: 'button' })`
  margin: -1rem -1.375rem;
  padding: 1.25rem 2rem;
  text-transform: uppercase;
  font-weight: 500;
  position: relative;
  z-index: 1;
  ${p =>
    p.current &&
    `
    z-index: 0;
  `}
  &:before {
    z-index: -1;
    border-radius: 0.125rem;
    padding: 0.25rem 0.5rem;
    content: '';
    position: absolute;
    top: 1rem;
    bottom: 1rem;
    left: 1.35rem;
    right: 1.35rem;
    background: ${p => p.theme.colors.darkFlash};
    ${p =>
      p.current &&
      `
      background: ${p.theme.colors.yellow};
    `}
  }
`

export const Day = styled(UI.SmallText).attrs({ as: 'time' })`
  display: block;
  margin: 2.5rem 0 1rem;
  color: ${p => p.theme.colors.gray};
`

export const List = styled.ul`
  li {
    margin-bottom: 0.5rem;
    display: flex;
    position: relative;
    color: ${p => p.theme.colors.maastricht};
    ${getTypeStyle('smallText')};
    ${above('lg')`
      ${getTypeStyle('text')};
    `}
    &:before {
      content: '—';
      color: ${p => p.theme.colors.yellow};
      margin-right: 0.5rem;
      ${above('xg')`
        position: absolute;
        right: 100%;
      `}
    }
  }
`
