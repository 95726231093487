import styled from 'styled-components'
import above from '@/utils/above'
import getColumnSize from '@/utils/getColumnSize'
import getTypeStyle from '@/utils/getTypeStyle'
import * as UI from '@/components/UI'

export const Section = styled.section`
  position: relative;
`

export const Cell = styled.div`
  ${above('sm')`
    width: ${getColumnSize(10)};
    margin: 0 ${getColumnSize(1)};
  `}
  ${above('md')`
    width: ${getColumnSize(8)};
    margin: 0 ${getColumnSize(2)};
  `}
  ${above('lg')`
    width: ${getColumnSize(6)};
    margin-left: ${getColumnSize(5)};
    margin-right: 0;
  `}
  ${above('xg')`
    width: ${getColumnSize(6)};
    margin-left: ${getColumnSize(4)};
  `}
`

export const Title = styled(UI.Subtitle)`
  font-family: ${p => p.theme.type.fonts.headline};
  margin-bottom: 3rem;
`

export const Item = styled.div`
  margin-bottom: 1.5rem;
`

export const ItemTitle = styled(UI.Label)`
  text-transform: uppercase;
  font-weight: 500;
  margin-bottom: 0.25rem;
  color: ${p => p.theme.colors.gray};
`

export const ItemText = styled.div`
  ${getTypeStyle('smallText')}
  ${above('md')`
    ${getTypeStyle('blogText')}
  `}
  ${above('lg')`
    ${getTypeStyle('text')}
  `}
`

export const DownloadTitle = styled(UI.Label)`
  text-transform: uppercase;
  font-weight: 500;
  margin-bottom: 1rem;
  color: ${p => p.theme.colors.gray};
`
