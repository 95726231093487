import React from 'react'
import * as UI from '@/components/UI'
import srcHeart from '@/assets/images/nobox-red.png'
import { Section, Cell } from './Gallery.css'

const Gallery = ({ content }) => {
  if (!content?.gallery?.[0]?.image) return null
  return (
    <Section>
      <UI.Parallax top='-25%' left='11%' force={1.3} offsetComp={40}>
        {offSet => (
          <UI.ImageLoose
            style={{
              transform: `translateY(${offSet}px) translateZ(0) rotate(180deg)`,
            }}
            src={srcHeart}
            alt={content.about_title}
            width='380'
            hiddenMobile
          />
        )}
      </UI.Parallax>
      <UI.Container>
        <UI.Grid>
          <Cell>
            <UI.Gallery gallery={content.gallery} color='yellow' />
          </Cell>
        </UI.Grid>
      </UI.Container>
    </Section>
  )
}

export default Gallery
