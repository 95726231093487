import styled from 'styled-components'
import above from '@/utils/above'
import getColumnSize from '@/utils/getColumnSize'

export const Section = styled.section`
  padding-bottom: 4rem;
  width: 100%;
  .slick-list {
    overflow: hidden;
  }
  ${above('md')`
    .slick-list {
    overflow: visible;
    } 
    padding-bottom: 7.5rem;
  `}
`

export const Cell = styled.div`
  width: ${getColumnSize(12)};
  ${above('sm')`
    width: ${getColumnSize(10)};
    margin: 0 ${getColumnSize(1)};
  `}
  ${above('md')`
    width: ${getColumnSize(8)};
    margin: 0 ${getColumnSize(2)};
  `}
  ${above('lg')`
    width: ${getColumnSize(6)};
    margin-left: ${getColumnSize(5)};
    margin-right: 0;
  `}
  ${above('xg')`
    width: ${getColumnSize(6)};
    margin-left: ${getColumnSize(4)};
  `}
`
