import styled from 'styled-components'
import above from '@/utils/above'
import getColumnSize from '@/utils/getColumnSize'
import * as UI from '@/components/UI'

export const Absolute = styled.div`
  pointer-events: none;
  z-index: 30;
  ${above('lg')`
    position: absolute;
    width: 100%;
    top: 4rem;
    bottom: 0;
  `}
  ${above('xg')`
    top: 5rem;
  `}
`

export const Sticky = styled.div`
  background: ${p => p.theme.colors.maastricht};
  ${above('lg')`
    background: none;
    position: sticky;
    width: 100%;
    top: 2rem;
  `}
`

export const Wrapper = styled.div`
  width: ${getColumnSize(12)};
  ${above('lg')`
    width: ${getColumnSize(4)};
  `}
  ${above('xg')`
    margin-left: -2rem;
    width: calc(2rem + ${getColumnSize(3)});
  `}
  ${above('lg')`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: stretch;
    height: 75vh;
  `}
`

export const Inner = styled.div`
  pointer-events: all;
  background: ${p => p.theme.colors.lightMaastricht};
  color: ${p => p.theme.colors.white};
  padding: 2.5rem 1rem 2rem;
  ${above('sm')`
    padding: 2.5rem 2rem 2rem;
  `}
  ${above('lg')`
    min-height: 100%;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
  `}
`

export const Kind = styled(UI.Label)`
  font-weight: 500;
  text-transform: uppercase;
  margin-bottom: 0.5rem;
  color: ${p => p.theme.colors.gray};
`

export const Title = styled(UI.Subtitle).attrs({ as: 'h1' })`
  font-family: ${p => p.theme.type.fonts.headline};
  margin-bottom: 1.5rem;
`

export const Description = styled(UI.SmallText)`
  margin-bottom: 0.5rem;
  color: ${p => p.theme.colors.gray};
`

export const Middle = styled.div`
  margin: 3rem 0 3.5rem;
`

export const SidebarDate = styled(UI.BlogText).attrs({ as: 'time' })`
  font-family: ${p => p.theme.type.fonts.headline};
  margin-bottom: 0.25rem;
  display: block;
`

export const Location = styled(UI.Footnote)`
  margin-top: 0.25rem;
  color: ${p => p.theme.colors.gray};
`

export const Separator = styled.div`
  background: ${p => p.theme.colors.snow}10;
  height: 1px;
  margin: 0.75rem 0;
`

export const Detail = styled(UI.Footnote)``

export const PriceWrapper = styled.div`
  margin: auto 0 1.5rem;
`

export const PriceCurrency = styled.div`
  display: flex;
  align-items: baseline;
  margin-bottom: 0.25rem;
`

export const Price = styled(UI.Subtitle)`
  font-family: ${p => p.theme.type.fonts.headline};
`

export const Currency = styled(UI.Footnote)`
  margin-left: 0.25rem;
`

export const PriceDetail = styled.div`
  color: ${p => p.theme.colors.gray};
`
