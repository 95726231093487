import React from 'react'
import { useContext } from '@/store'
import getWeekDay from '@/utils/getWeekDay'
import getFullDate from '@/utils/getFullDate'
import * as UI from '@/components/UI'
import {
  Absolute,
  Sticky,
  Wrapper,
  Inner,
  Kind,
  Title,
  Description,
  Middle,
  SidebarDate,
  Location,
  Separator,
  Detail,
  PriceWrapper,
  PriceCurrency,
  Price,
  Currency,
  PriceDetail,
} from './Sidebar.css'

const Sidebar = ({ content, ...props }) => {
  const { locale } = useContext()
  const weekday = getWeekDay(content.date, locale)
  const toweekday = getWeekDay(content.to_date, locale)
  const fullDate = getFullDate(content.to_date)

  return (
    <Absolute>
      <Sticky>
        <UI.Container>
          <UI.Grid>
            <Wrapper {...props}>
              <Inner>
                <Kind>{content.sidebar_kind}</Kind>
                <Title>{content.meta_title}</Title>
                <Description>{content.sidebar_description}</Description>
                <Middle>
                  <SidebarDate>
                    {weekday} {content.formatedDate}
                  </SidebarDate>
                  {content.to_date && (
                    <SidebarDate>
                      {toweekday} {fullDate}
                    </SidebarDate>
                  )}
                  <Location>{content.location}</Location>
                  <Separator />
                  <Detail>{content.sidebar_detail}</Detail>
                </Middle>
                <PriceWrapper>
                  <PriceCurrency>
                    <Price>{content.sidebar_price}</Price>
                    <Currency>{content.sidebar_currency}</Currency>
                  </PriceCurrency>
                  <PriceDetail>{content.sidebar_price_detail}</PriceDetail>
                </PriceWrapper>
                <UI.Button to={content.sidebar_button_link} appearance='yellow'>
                  {content.sidebar_button}
                </UI.Button>
              </Inner>
            </Wrapper>
          </UI.Grid>
        </UI.Container>
      </Sticky>
    </Absolute>
  )
}

export default Sidebar
